.ZT_KFq_header {
  top: var(--spacing-base);
  height: 60px;
  padding-inline: var(--spacing-2);
  background: var(--bg-semi-transparent);
  backdrop-filter: var(--blur-lg);
  border-radius: var(--radius-5);
  z-index: var(--z-idx-3);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
}

.ZT_KFq_header--brand {
  font-size: var(--font-size-lg);
  font-weight: bold;
}

@media screen and (width <= 767px) {
  .ZT_KFq_header--brand {
    font-size: var(--font-size-base);
  }
}

.dbghqq_footer {
  width: 100%;
  height: 60px;
  color: hsla(var(--clr-foreground), .5);
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 0;
}

.L54TZG_landing-page {
  height: 100%;
  padding-inline: var(--spacing-base);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.scSIPa_hero {
  height: 50svh;
  margin-block: var(--spacing-base);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.scSIPa_hero-main {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  display: flex;
}

.scSIPa_hero-main--greetings {
  font-size: var(--font-size-xxl);
}

.scSIPa_hero-main--greetings--blur {
  color: hsla(var(--clr-foreground), .5);
}

.scSIPa_hero-main--summary {
  font-size: var(--font-size-base);
  color: hsla(var(--clr-foreground), .7);
}

.scSIPa_hero-cta {
  margin-top: var(--spacing-base);
  display: flex;
}

@media screen and (width <= 767px) {
  .scSIPa_hero-main--greetings {
    font-size: var(--font-size-lg);
  }

  .scSIPa_hero-main--summary {
    font-size: var(--font-size-base);
  }
}

.KQZbna_button {
  padding: var(--spacing-base) var(--spacing-base);
  border-radius: var(--radius-5);
  justify-content: space-between;
  align-items: center;
  gap: .35rem;
  transition: background .25s, color .25s;
  display: flex;
}

.KQZbna_button-primary {
  background: hsl(var(--clr-foreground));
  color: hsl(var(--clr-background));
}

.KQZbna_button-primary:hover {
  background: hsla(var(--clr-foreground), .85);
}

.KQZbna_button-secondary {
  color: hsl(var(--clr-foreground));
  background: none;
}

.KQZbna_button-secondary:hover {
  background: hsl(var(--clr-muted));
}

.A1skyq_skills {
  gap: var(--spacing-base);
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  display: grid;
}

.A1skyq_skills--dsa-stats {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.A1skyq_skills--dsa-stats > div {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.A1skyq_skills--dsa-stats--lc-breakdown {
  gap: var(--spacing-base);
  flex-direction: column;
  width: 100%;
  max-width: 30%;
  display: flex;
}

.A1skyq_skills--dsa-stats--lc-breakdown-item {
  width: 100%;
  padding-inline: var(--spacing-base);
  padding-block: var(--font-size-base);
  background: var(--bg-semi-transparent);
  border-radius: var(--radius-5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.A1skyq_skills--dsa-stats--lc-breakdown-item--easy {
  color: hsl(var(--clr-lc-easy));
}

.A1skyq_skills--dsa-stats--lc-breakdown-item--medium {
  color: hsl(var(--clr-lc-medium));
}

.A1skyq_skills--dsa-stats--lc-breakdown-item--hard {
  color: hsl(var(--clr-lc-hard));
}

.A1skyq_skills--skills-cloud {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-base);
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.A1skyq_skills--skills-cloud--item {
  font-size: var(--font-size-base);
  background: var(--bg-semi-transparent);
  border-radius: var(--radius-5);
  padding-inline: var(--spacing-1);
  padding-block: var(--spacing-base);
}

@media screen and (width <= 767px) {
  .A1skyq_skills {
    grid-template-columns: 1fr;
  }
}

@media screen and (width <= 436px) {
  .A1skyq_skills--dsa-stats {
    flex-direction: column;
  }
}

.Bb35Mq_card {
  border: 2px solid hsla(var(--clr-border), .1);
  border-radius: var(--radius-5);
  height: auto;
  min-height: 240px;
  padding: var(--spacing-base);
  cursor: pointer;
  background: none;
  justify-content: center;
  align-items: center;
  transition: background .1s, backdrop-filter .1s;
  display: flex;
}

.Bb35Mq_card:hover {
  background: var(--bg-semi-transparent);
  backdrop-filter: var(--blur-lg);
}
/*# sourceMappingURL=index.f8cc518d.css.map */
